import React from 'react';
import ReactGA from 'react-ga';

import Footer from './components/Footer'

import spooktober from './assets/spooktober.png'
import './App.css';


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    
      ReactGA.initialize('UA-145479778-8');
      ReactGA.pageview(window.location.pathname);
  }

  setReactGAClickEvent = (itemClicked) => {
    ReactGA.event({
      category: 'User',
      action: `Click ${itemClicked}`
    });
  }

  onContactClick = () => {
    this.setReactGAClickEvent('contact')
  }

  render() {
    return (
      <div className="App">
        <div className="content-wrap">
          <header className="App-header">
            <div
              className='header'
            >
              <h1 className="title">Games For All Seasons</h1>
              <h2 className="subtitle">You deserve a new experience each season</h2>
              <hr></hr>
            </div>
          </header>
          <div className="body">
            <div
              className="coming-soon"
              style={{ backgroundImage: `url(${spooktober})` }}
            >
              <div className="coming-soon-text"><span className="bold">10.13.20:</span> Spooktober entries coming soon...</div>
            </div>
          </div>
        </div>
        <Footer 
          onContactClick={this.onContactClick}
        />
      </div>
    );
  }
}

export default App;
